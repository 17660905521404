import React from "react";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/Seo";
import {Box, Button, Container, T2, Image} from "../../styles/Styles";
import iconCite from "../../img/icon-quote.png";
import person from "../../img/ian-deason-hex.png";

// benefits videos
import videoWindAlerts from "../../img/wind-alert.mp4";
import videoAirport from "../../img/airport-video.mp4";
import videoLightning from "../../img/lightning.mp4";
import videoWindSpeed from "../../img/wind-speed.mp4";
// benefits posters
import posterWindAlerts from "../../img/wind-alert-cover.jpg";
import windSpeedCover from "../../img/wind-alert-cover.jpg";
import posterAirport from "../../img/airport-video-cover.jpg";
import posterLightningCover from "../../img/lightning-cover.jpg";
import flightDelay from "../../img/reduce-flight-delay-cover.png";

import hero from "../../img/hero-aviation-ind.png";
import Clients from "../../components/Homepage/Clients";

const benefits = [
    {
        webm: null,
        mp4: videoWindAlerts,
        poster: posterWindAlerts,
        title: "オペレーションの最適化",
        caption: "高速な風を検知するアラートを設定",
        text: `前もって悪天候に備えるという事には、適切なスタッフレベル、除氷要員、ゲート計画、乗務員の時間切れ、ベンダーの割り当て、TARMACルールを含みます。`,
    },
    {
        webm: null,
        mp4: videoWindSpeed,
        poster: windSpeedCover,
        title: "安全性の向上",
        caption: "時間とともに関心のある天候状況が変化するのを確認",
        text: `どのタイミングでランプを閉鎖し、地上設備や航空機の保全確保をするのかを理解し、翼の上と下での作業員そして乗客の安全を確実に確保しましょう。`,
    },
    {
        webm: null,
        mp4: videoAirport,
        poster: posterAirport,
        title: "ツールをひとつに",
        caption: "支局毎の気象情報をチェック",
        text: `全ての気象予報情報を世界中の拠点を通じて一つにすることで、本部や支局、パイロットとの同調を向上し、空港と協力した意思決定が容易になります。`
    },
    {
        webm: null,
        mp4: null,
        poster: flightDelay,
        title: "フライト遅延の減少",
        caption: `雷が空港に近づいているのを認識`,
        text: `荒れ模様の天候となる正確なタイミングを知ることで、航空機をプッシュバックし、不要な警戒を避け、オンタイムの実績と顧客満足を向上できます。`
    },
    {
        webm: null,
        mp4: videoLightning,
        poster: posterLightningCover,
        title: "フライト計画を向上",
        caption: "ルート計画を向上",
        text: `重大な天候事象を1-3時間前に把握し、もっとも安全なルート、ならびに代替<nobr>着陸用</nobr>の空港を選択し、空中でパイロットが最善の意思決定をできるよう支援。<nobr>そして</nobr>、目的地空港の気象状態を事前把握することを可能とする。`
    }
];

export default class AviationIndustry extends React.Component {
    render() {
        return (
            <Layout>
                <SEO
                    title="航空 | tomorrow.io"
                    image={hero}
                    pathname={this.props.location.pathname}
                />

                <Container>
                    <Box display="flex" flexWrap="wrap" pb={4}>
                        <Box
                            width={[1, 1 / 2]}
                            pr={[0, 4]}
                            pt={5}
                        >
                            <Box fontSize={17} mb={3}>
                                今までにランプを稼働、閉鎖したり、欠航、代替着陸するのを目の当たりにして、それを不思議に思ったことがありますか？
                            </Box>
                            <T2 as="h1" mb={4}>
                                天候に驚かされることを<nobr>やめましょう</nobr>
                            </T2>
                            <Button
                                as="a"
                                display={["block", "inline-block"]}
                                mb={2}
                                href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
                            >
                                tomorrow.ioデモ取得
                            </Button>{" "}
                            <Button
                                outline="1"
                                as="a"
                                display={["block", "inline-block"]}
                                mb={2}
                                href="https://www.tomorrow.io/api-signup/"
                            >
                                APIキー取得
                            </Button>
                        </Box>
                        <Box width={[1, 1 / 2]} pt={2} px={3} textAlign="center">
                            <Image
                                maxHeight="380px"
                                src={hero}
                                alt=""
                            />
                        </Box>
                    </Box>
                </Container>


                <section className="c-section py-5">
                    <div className="container">
                        <Clients/>
                    </div>
                </section>

                <section className="c-section">
                    <div className="container">
                        <div className="col-lg-8 mx-auto text-center px-2">
                            <h2 className="h-3 mb-5">
                                毎年、航空業界は天候関連による遅延や代替着陸、<br/>欠航などで多額な損害を<nobr>被っています。</nobr>
                            </h2>
                            <p className="fs-lg">
                            tomorrow.ioの分単位、空港レベルの予報により、航空会社ならびに空港オペレーションチームが必要な情報を手に入れることができます。雷、降水量タイプと激しさ、<nobr>可視性</nobr>、雲量、風速、極端な気温等といった情報により、天候状態に応じた対策を<nobr>立て、</nobr>乗務員と乗客を危険から守り、そして顧客満足度の向上に役立てます。
                            </p>
                        </div>

                        {benefits.map((item, i) => (
                            <div key={i} className="row justify-content-center py-4 py-lg-6">

                                <div
                                    className={`col-lg-5 pr-lg-4 ${
                                        i % 2 === 0 ? "order-lg-last" : ""
                                    }`}
                                >
                                    <h3
                                        className="h-3"
                                        dangerouslySetInnerHTML={{__html: item.title}}
                                    />
                                    <p dangerouslySetInnerHTML={{__html: item.text}}/>
                                </div>

                                <div className="col-lg-7">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <video
                                            className="embed-responsive-item shadow"
                                            poster={item.poster}
                                            width="800"
                                            height="480"
                                            controls={false}
                                            muted
                                            playsInline
                                            autoPlay
                                            loop
                                        >
                                            {item.webm && (
                                                <source src={item.webm} type="video/webm"/>
                                            )}
                                            {item.mp4 && <source src={item.mp4} type="video/mp4"/>}
                                            } Your browser does not support the video tag
                                        </video>
                                    </div>
                                    <div className="text-center px-4 py-2">{item.caption}</div>

                                </div>
                            </div>
                        ))}
                        <div className="text-center pt-6">

                            <a
                                className="btn btn-lg btn-primary h-3 "
                                href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
                            >
                                tomorrow.ioデモ取得
                            </a>

                        </div>
                    </div>
                </section>

                <section className="c-section pt-5 ">

                    <div className="col-lg-7 mx-auto text-center">
                        <img className="mb-3" src={iconCite} alt=""/>
                        <p
                            className="fs-lg font-italic mb-4"
                            style={{fontFamily: "Georgia, serif"}}
                        >
                            このツールは、日々空港リーダーたちが直面している決断しなければいけない<br/>プレッシャーをよく理解してデザインされています。
                        </p>
                        <p className="mb-3">
                            — イアン・デーソン氏、SVPカスタマーエクスペリエンス、JetBlue
                        </p>
                        <img className="mb-5" width={100} src={person} alt=""/>
                        <div className="text-center">
                            <a
                                className="btn btn-lg btn-primary h-3 "
                                href="/customer-stories/jetblue/"
                            >
                                もっと読む
                            </a>
                        </div>
                    </div>

                </section>

            </Layout>
        );
    }
}
